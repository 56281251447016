<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      min-width="100%"
      :src="require('@/assets/blackshepherdsfinal.jpg')"
      class="white--text"
    >
      <v-img
        max-width="880px"
        width="85%"
        style="
          top: 30px;
          left: auto;
          margin-right: auto;
          margin-left: auto;
          max-width: 880px;
        "
        :src="require('@/assets/blackshepherdsfinal_title.png')"
      />
      <v-container
        class="center fill-height px-4 py-12"
        style="margin: 0"
      >
        <v-responsive
          class="d-flex align-left mx-auto"
          style="position: absolute; bottom: 20px"
          justify="center"
          width="100%"
        >
          <base-section-heading>
            <v-img
              max-width="620px"
              width="75%"
              style="
                left: auto;
                margin-right: auto;
                margin-left: auto;
                max-width: 620px;
              "
              :src="require('@/assets/das-city-escape-game.png')"
              @click="$vuetify.goTo('#theme-features')"
            />
            <v-btn
              text
              large
              @click="$vuetify.goTo('#dusseldorf')"
            >
              <span v-if="language === '/de'">DÜSSELDORF</span>
              <span v-if="language === '/en'">DUSSELDORF</span>
            </v-btn>
            <v-btn
              text
              large
              @click="$vuetify.goTo('#koln')"
            >
              <span v-if="language === '/de'">KÖLN</span>
              <span v-if="language === '/en'">COLOGNE</span>
            </v-btn>
            <v-btn
              text
              large
              @click="$vuetify.goTo('#wuppertal')"
            >
              WUPPERTAL
            </v-btn>
            <!--
            <div style="font-size: 3.3vw">
              <a @click="$vuetify.goTo('#dusseldorf')">DÜSSELDORF</a>
              <span class="font-weight-bold ml-6 mr-4 my-4">|</span>
              <a @click="$vuetify.goTo('#koln')">KÖLN</a>
              <span class="font-weight-bold ml-6 mr-4 my-4"> |</span>
              <a @click="$vuetify.goTo('#wuppertal')">WUPPERTAL</a>
            </div> -->
          </base-section-heading>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    data () {
      return {
        show: '',
        language: '/de',
      }
    },

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        // const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        // return `calc(${height} - ${this.$vuetify.application.top}px)`
        return '100vh'
      },
    },

    watch: {
      $route (to, from) {
        this.language = this.$route.path
      },
    },

    created () {
      this.language = this.$route.path
      if (this.$route.path === '/') {
        this.language = '/de'
      }
    },
  }
</script>
